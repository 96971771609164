import * as React from "react"
import LineIcon from "react-lineicons"
import "./footer.css"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  const isBrowser = typeof window !== "undefined"

  return (
    <>
      <section className="footer-area footer-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 mt-5">
              <div className="footer-logo">
                <Link to="/">
                  <StaticImage
                    imgClassName="fluid-img"
                    height={60}
                    width={100}
                    src="../images/LappyNation.png"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="LappyNation"
                    placeholder="blurred"
                  />
                </Link>
              </div>
              <ul className="social mt-10">
                <li className="m-0">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/Lappy-Nation-102591518601459/"
                  >
                    {isBrowser && <LineIcon name="facebook-filled" />}
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/in/lappy-nation-37195621a"
                  >
                    {isBrowser && <LineIcon name="linkedin-original" />}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 mt-5">
              <h4 className="text-white">Company</h4>
              <div className="footer-support">
                <ul className="navbar-nav ml-auto text-white">
                  <li className="nav-item">
                    <Link className="page-scroll" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="page-scroll" to="/why-us">
                      Why Us?
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="page-scroll" to="/directors-message">
                      Director's Message
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link className="page-scroll" to="/#contact">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="page-scroll" to="/terms">
                      Terms and Conditions
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="page-scroll" to="/faq">
                      FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 mt-5">
              <h4 className="text-white">Contact Details</h4>
              <div className="footer-support">
                <p className="text-white">Address</p>
                <p className="text-white">
                  #13/5, Jacobpura, Opp. DHBVN office, Gurugram, Haryana 122001
                </p>
              </div>
              <div className="footer-support">
                <p className="number text-white">Phone : +91 83760 07110</p>
                <p className="mail text-white">E-mail : info@lappynation.com</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Footer
