import React, { useEffect, useState } from "react"
import "./header.css"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Navbar } from "react-bootstrap"

const Header = () => {
  const isActive = ({ href, location: { pathname, hash } }) => {
    let currentPath = pathname + hash
    if (currentPath.length > 1 && currentPath.slice(-1) === "/") {
      currentPath = currentPath.slice(0, -1)
    }
    const active = href === currentPath
    return active ? { className: "active" } : {}
  }

  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50)
    })
  }, [])

  return (
    <>
      <section className={scroll ? "navbar-area sticky" : "navbar-area"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Navbar collapseOnSelect expand="lg" variant="dark">
                <Link className="navbar-brand" to="/">
                  <StaticImage
                    src="../images/LappyNationFull.png"
                    width={150}
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="LappyNation"
                    placeholder="blurred"
                  />
                </Link>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse
                  id="responsive-navbar-nav"
                  className="sub-menu-bar justify-content-end"
                >
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <Link
                        activeClassName="active"
                        getProps={isActive}
                        className="page-scroll"
                        to="/"
                      >
                        home
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClassName="active"
                        getProps={isActive}
                        className="page-scroll"
                        to="/laptops"
                      >
                        Laptop on rent
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        activeClassName="active"
                        getProps={isActive}
                        className="page-scroll"
                        to="/desktops"
                      >
                        Desktop on rent
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        activeClassName="active"
                        getProps={isActive}
                        className="page-scroll"
                        to="/#contact"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header
