import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"

const WhatsApp = () => (
  <a
    href="https://api.whatsapp.com/send?phone=+918376007110&text=I am interested in renting the Laptop/Desktop from Lappy Nation"
    className="back-to-top"
  >
    <FontAwesomeIcon icon={faWhatsapp} />
  </a>
)

export default WhatsApp
